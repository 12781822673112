var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex mb-3 justify-content-center"},[_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('div',{staticClass:"rounded bg-primary d-flex icon align-items-center justify-content-center mr-2 text-white font-weight-bold"},[_vm._v(" 1 ")]),_vm._v(" Create Order "),_c('feather-icon',{staticClass:"mx-2",attrs:{"icon":"ChevronRightIcon","size":"35"}})],1),_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('div',{staticClass:"rounded bg-primary d-flex icon align-items-center justify-content-center mr-2 text-white font-weight-bold"},[_vm._v(" 2 ")]),_vm._v(" Key in Details "),_c('feather-icon',{staticClass:"mx-2",attrs:{"icon":"ChevronRightIcon","size":"35"}})],1),_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('div',{staticClass:"rounded notActive d-flex icon align-items-center justify-content-center mr-2 text-white font-weight-bold"},[_vm._v(" 3 ")]),_vm._v(" Finalize Order "),_c('feather-icon',{staticClass:"mx-2",attrs:{"icon":"ChevronRightIcon","size":"35"}})],1),_vm._m(0)]),_c('validation-observer',{ref:"simpleRules"},[_c('b-card',[_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('div',{staticClass:"rounded-circle icon d-flex icon align-items-center justify-content-center mr-1"},[_c('feather-icon',{attrs:{"icon":"CalendarIcon","size":"35","color":"white"}})],1),_c('h3',{staticClass:"mr-5 text"},[_vm._v("Delivery Date")]),_c('div',{staticClass:"w-75"},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Delivery Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control mt-1",attrs:{"placeholder":"Choose Date","readonly":"readonly","config":{
                  minDate: 'today',
                }},model:{value:(_vm.form.delivery_datetime),callback:function ($$v) {_vm.$set(_vm.form, "delivery_datetime", $$v)},expression:"form.delivery_datetime"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)])]),_c('b-card',[_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('div',{staticClass:"rounded-circle icon d-flex icon align-items-center justify-content-center mr-1"},[_c('feather-icon',{attrs:{"icon":"TruckIcon","size":"35","color":"white"}})],1),_c('h3',{staticClass:"mr-5 text"},[_vm._v("Origin Point")]),_c('div',{staticClass:"w-75"},[_c('div',[_c('validation-provider',{attrs:{"name":"Origin Point","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.user_id),expression:"form.user_id"}],staticClass:"form-control form-select rounded",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "user_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"","disabled":""}},[_vm._v("Select Origin Point")]),_vm._l((_vm.address),function(address){return _c('option',{key:address.id,domProps:{"value":address}},[_vm._v(" "+_vm._s(address.name)+" | "+_vm._s(address.branch.address)+" ")])})],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])])]),_c('b-card',[_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"rounded-circle icon d-flex icon align-items-center justify-content-center mr-1"},[_c('feather-icon',{attrs:{"icon":"HomeIcon","size":"35","color":"white"}})],1),_c('h3',{staticClass:"mr-5 text"},[_vm._v("Sender's Details")]),_c('div',{staticClass:"w-75"},[_c('div',[_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Sender's First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.sender_first_name),expression:"form.sender_first_name"}],staticClass:"form-control mr-2",attrs:{"type":"text","placeholder":"First Name","aria-label":"First Name","state":errors.length > 0 ? false : null},domProps:{"value":(_vm.form.sender_first_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "sender_first_name", $event.target.value)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Sender's Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.sender_last_name),expression:"form.sender_last_name"}],staticClass:"form-control mr-2",attrs:{"type":"text","placeholder":"Last Name","aria-label":"Last Name"},domProps:{"value":(_vm.form.sender_last_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "sender_last_name", $event.target.value)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"mb-2"},[_c('validation-provider',{attrs:{"name":"Sender's Phone Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.sender_phone_number),expression:"form.sender_phone_number"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Phone Number","aria-label":"Phone Number"},domProps:{"value":(_vm.form.sender_phone_number)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "sender_phone_number", $event.target.value)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Sender's Unit No.","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.sender_unit_number),expression:"form.sender_unit_number"}],staticClass:"form-control mr-2",attrs:{"type":"text","placeholder":"Unit No.","aria-label":"Unit No.","state":errors.length > 0 ? false : null},domProps:{"value":(_vm.form.sender_unit_number)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "sender_unit_number", $event.target.value)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Sender's Address Line 1","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.sender_address_line_1),expression:"form.sender_address_line_1"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Address Line 1","aria-label":"Address Line 1","state":errors.length > 0 ? false : null},domProps:{"value":(_vm.form.sender_address_line_1)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "sender_address_line_1", $event.target.value)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"mb-2"},[_c('validation-provider',{attrs:{"name":"Sender's Address Line 2","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.sender_address_line_2),expression:"form.sender_address_line_2"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Address Line 2","aria-label":"Address Line 2","state":errors.length > 0 ? false : null},domProps:{"value":(_vm.form.sender_address_line_2)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "sender_address_line_2", $event.target.value)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Sender's Postcode","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.sender_postcode),expression:"form.sender_postcode"}],staticClass:"form-control mr-2",attrs:{"type":"text","placeholder":"Postcode","aria-label":"Postcode","state":errors.length > 0 ? false : null},domProps:{"value":(_vm.form.sender_postcode)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "sender_postcode", $event.target.value)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Sender's Area","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.sender_area),expression:"form.sender_area"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Area","aria-label":"Area","state":errors.length > 0 ? false : null},domProps:{"value":(_vm.form.sender_area)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "sender_area", $event.target.value)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Sender's State","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.sender_state),expression:"form.sender_state"}],staticClass:"form-control form-select rounded mr-2",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "sender_state", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"","disabled":"","selected":""}},[_vm._v("State")]),_vm._l((_vm.state),function(state,index){return _c('option',{key:index},[_vm._v(" "+_vm._s(state.state_name)+" ")])})],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Sender's Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.sender_country),expression:"form.sender_country"}],staticClass:"form-control form-select rounded",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "sender_country", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"","disabled":"","selected":""}},[_vm._v("Country")]),_vm._l((_vm.country),function(country,index){return _c('option',{key:index},[_vm._v(" "+_vm._s(country.value)+" ")])})],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)])])]),_c('b-card',[_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"rounded-circle icon d-flex icon align-items-center justify-content-center mr-1"},[_c('feather-icon',{attrs:{"icon":"HomeIcon","size":"35","color":"white"}})],1),_c('h3',{staticClass:"mr-5 text"},[_vm._v("Recipient's Details")]),_c('div',{staticClass:"w-75"},[_c('div',[_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Recipient's First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.recipient_first_name),expression:"form.recipient_first_name"}],staticClass:"form-control mr-2",attrs:{"type":"text","placeholder":"First Name","aria-label":"First Name","state":errors.length > 0 ? false : null},domProps:{"value":(_vm.form.recipient_first_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "recipient_first_name", $event.target.value)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Recipient's Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.recipient_last_name),expression:"form.recipient_last_name"}],staticClass:"form-control mr-2",attrs:{"type":"text","placeholder":"Last Name","aria-label":"Last Name"},domProps:{"value":(_vm.form.recipient_last_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "recipient_last_name", $event.target.value)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"mb-2"},[_c('validation-provider',{attrs:{"name":"Recipient's Phone Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.recipient_phone_number),expression:"form.recipient_phone_number"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Phone Number","aria-label":"Phone Number"},domProps:{"value":(_vm.form.recipient_phone_number)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "recipient_phone_number", $event.target.value)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Recipient's Unit No.","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.recipient_unit_number),expression:"form.recipient_unit_number"}],staticClass:"form-control mr-2",attrs:{"type":"text","placeholder":"Unit No.","aria-label":"Unit No.","state":errors.length > 0 ? false : null},domProps:{"value":(_vm.form.recipient_unit_number)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "recipient_unit_number", $event.target.value)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Recipient's Address Line 1","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.recipient_address_line_1),expression:"form.recipient_address_line_1"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Address Line 1","aria-label":"Address Line 1","state":errors.length > 0 ? false : null},domProps:{"value":(_vm.form.recipient_address_line_1)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "recipient_address_line_1", $event.target.value)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"mb-2"},[_c('validation-provider',{attrs:{"name":"Recipient's Address Line 2","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.recipient_address_line_2),expression:"form.recipient_address_line_2"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Address Line 2","aria-label":"Address Line 2","state":errors.length > 0 ? false : null},domProps:{"value":(_vm.form.recipient_address_line_2)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "recipient_address_line_2", $event.target.value)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Recipient's Postcode","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.recipient_postcode),expression:"form.recipient_postcode"}],staticClass:"form-control mr-2",attrs:{"type":"text","placeholder":"Postcode","aria-label":"Postcode","state":errors.length > 0 ? false : null},domProps:{"value":(_vm.form.recipient_postcode)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "recipient_postcode", $event.target.value)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Recipient's Area","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.recipient_area),expression:"form.recipient_area"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Area","aria-label":"Area","state":errors.length > 0 ? false : null},domProps:{"value":(_vm.form.recipient_area)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "recipient_area", $event.target.value)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Recipient's State","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.recipient_state),expression:"form.recipient_state"}],staticClass:"form-control form-select rounded mr-2",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "recipient_state", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"","disabled":"","selected":""}},[_vm._v("State")]),_vm._l((_vm.state),function(state,index){return _c('option',{key:index},[_vm._v(" "+_vm._s(state.state_name)+" ")])})],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Recipient's Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.recipient_country),expression:"form.recipient_country"}],staticClass:"form-control form-select rounded",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "recipient_country", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"","disabled":"","selected":""}},[_vm._v("Country")]),_vm._l((_vm.country),function(country,index){return _c('option',{key:index},[_vm._v(" "+_vm._s(country.value)+" ")])})],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)])])]),_c('b-card',[_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"rounded-circle icon d-flex icon align-items-center justify-content-center mr-1"},[_c('feather-icon',{attrs:{"icon":"BoxIcon","size":"35","color":"white"}})],1),_c('h3',{staticClass:"mr-5 text"},[_vm._v("Product Details")]),_c('div',{staticClass:"w-75"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mr-2"},[_c('validation-provider',{attrs:{"name":"Product Categories","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('h4',[_vm._v("Product Categories")]),_vm._l((_vm.product),function(product,index){return _c('b-form-group',{key:index},[_c('b-form-radio',{attrs:{"name":"product","value":product.value},model:{value:(_vm.form.product_category),callback:function ($$v) {_vm.$set(_vm.form, "product_category", $$v)},expression:"form.product_category"}},[_vm._v(_vm._s(product.value))])],1)}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.product_category),expression:"product_category"}],staticClass:"form-control mt-1",attrs:{"type":"text","placeholder":"Others","aria-label":"Others","disabled":_vm.form.product_category !== 'Others'},domProps:{"value":(_vm.product_category)},on:{"input":function($event){if($event.target.composing){ return; }_vm.product_category=$event.target.value}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"mr-2"},[_c('validation-provider',{attrs:{"name":"Packaging","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('h4',[_vm._v("Packaging")]),_vm._l((_vm.packaging),function(packaging,index){return _c('b-form-group',{key:index},[_c('b-form-radio',{attrs:{"name":"packaging","value":packaging.value},model:{value:(_vm.form.packaging),callback:function ($$v) {_vm.$set(_vm.form, "packaging", $$v)},expression:"form.packaging"}},[_vm._v(_vm._s(packaging.value))])],1)}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.optionPackaging),expression:"optionPackaging"}],staticClass:"form-control mt-1",attrs:{"type":"text","placeholder":"Others","aria-label":"Others","disabled":_vm.form.packaging !== 'Others'},domProps:{"value":(_vm.optionPackaging)},on:{"input":function($event){if($event.target.composing){ return; }_vm.optionPackaging=$event.target.value}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"mr-2"},[_c('validation-provider',{attrs:{"name":"Remarks","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('h4',[_vm._v("Remarks")]),_vm._l((_vm.remarks),function(remarks,index){return _c('b-form-group',{key:index},[_c('b-form-radio',{attrs:{"name":"remarks","value":remarks.value},model:{value:(_vm.form.remarks),callback:function ($$v) {_vm.$set(_vm.form, "remarks", $$v)},expression:"form.remarks"}},[_vm._v(_vm._s(remarks.value))])],1)}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.optionRemarks),expression:"optionRemarks"}],staticClass:"form-control mt-1",attrs:{"type":"text","placeholder":"Others","aria-label":"Others","disabled":_vm.form.remarks !== 'Others'},domProps:{"value":(_vm.optionRemarks)},on:{"input":function($event){if($event.target.composing){ return; }_vm.optionRemarks=$event.target.value}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"w-50"},[_c('validation-provider',{attrs:{"name":"Quantity","rules":"required|positive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.quantity),expression:"form.quantity"}],staticClass:"form-control mt-1",attrs:{"type":"text","placeholder":"Quantity","aria-label":"Quantity"},domProps:{"value":(_vm.form.quantity)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "quantity", $event.target.value)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('validation-provider',{attrs:{"name":"Temperature","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.temperature),expression:"form.temperature"}],staticClass:"form-control form-select rounded mt-1",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "temperature", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"","disabled":"","selected":""}},[_vm._v(" Select Temperature ")]),_vm._l((_vm.sample),function(sample,index){return _c('option',{key:index},[_vm._v(" "+_vm._s(sample.value)+" ")])})],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('validation-provider',{attrs:{"name":"Size","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control mt-1",attrs:{"id":"cardNumber","raw":false,"options":_vm.options.boxSize,"placeholder":"Length (cm) x Width (cm) x Height (cm)"},model:{value:(_vm.form.item_size),callback:function ($$v) {_vm.$set(_vm.form, "item_size", $$v)},expression:"form.item_size"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('validation-provider',{attrs:{"name":"Weight (kg)","rules":"required|positive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.item_weight),expression:"form.item_weight"}],staticClass:"form-control mt-1",attrs:{"type":"text","placeholder":"Weight (kg)","aria-label":"Weight (kg)"},domProps:{"value":(_vm.form.item_weight)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "item_weight", $event.target.value)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('validation-provider',{attrs:{"name":"SKU","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.sku),expression:"form.sku"}],staticClass:"form-control mt-1",attrs:{"type":"text","placeholder":"SKU","aria-label":"SKU"},domProps:{"value":(_vm.form.sku)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "sku", $event.target.value)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])])])]),_c('div',{staticClass:"d-flex justify-content-center align-items-center mt-3"},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-center",modifiers:{"modal-center":true}}],staticClass:"box-shadow-1 px-5",attrs:{"variant":"gradient-primary"},on:{"click":_vm.onSubmit}},[_vm._v("Submit")])],1)],1),_c('div',[_c('b-modal',{ref:"my-modal",attrs:{"hide-footer":"","hide-header":"","title":"Alert!"}},[_c('div',{staticClass:"d-block text-center my-5"},[_c('h4',[_vm._v(" Sorry, Destination Postcode not supported. Please try another. ")])]),_c('b-button',{staticClass:"mt-2",attrs:{"variant":"primary","block":""},on:{"click":function($event){return _vm.hideModal()}}},[_vm._v("Confirm")])],1)],1),_c('div',[_c('b-modal',{ref:"confirmation-modal",attrs:{"hide-footer":"","hide-header":"","title":"Alert!"}},[_c('div',{staticClass:"d-block text-center my-5"},[_c('h4',[_vm._v(_vm._s(this.doubleCharge))])]),_c('div',{staticClass:"d-flex"},[_c('b-button',{staticClass:"mr-2 mt-2",attrs:{"variant":"light","block":""},on:{"click":function($event){return _vm.continueEditing()}}},[_vm._v("Edit Order")]),_c('b-button',{staticClass:"mr-2 mt-2",attrs:{"variant":"primary","block":""},on:{"click":function($event){return _vm.calculateOrder()}}},[_vm._v("Confirm")])],1)])],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('div',{staticClass:"rounded notActive d-flex icon align-items-center justify-content-center mr-2 text-white font-weight-bold"},[_vm._v(" 4 ")]),_vm._v(" Proceed Payment ")])}]

export { render, staticRenderFns }